//
// TABLE STYLE - investing data
//

.table {
  letter-spacing: $smbx-font-primary-letter-spacing;
  //table style
  //: 0px 25px 0px 25px;
  //table-layout: fixed;

      tr td:first-child, tr th:first-child {
        padding-left: 25px;
      }
      th {
        color: #666666; //optimize
        font-size: 12px;
        font-weight: 300;
      }
      td {
        font-size: 15px;
        font-weight: 300;
      }
      .investor {
        font-weight: 500;
      }
      .status {
        font-weight: 500;

            &.roadshow {
              color: $smbx-color-roadshow;
            }
            &.prebid {
              color: $smbx-color-prebid;
            }
            &.auction {
              color: $smbx-color-auction;
            }
            &.closed {
              color: $smbx-color-auction;
            }
      }
      .date, .time {
        color: #9B9B9B;
        font-weight: 30;
        text-align: left;
      }
      .date {
        padding-right: 25px;
      }

}
.smbx-content {
  background-color: white;
  margin-top: 25px;
  padding: 25px;
}
.tab-content.smbx-content {
  min-height: 350px;
}
.nav-tabs {
  margin: 0px;
  margin-bottom: -26px;
  padding: 0px;

      a.nav-link {
        border: none;
        width: 200px;
        height: 50px;
        padding-top: 15px;
        font-size: 15px;
        text-align: center;
        background-color: #E5E5E5;//$smbx-color-light-grey-2;
        color: $smbx-color-mid-grey;
        border-right: 1px solid $smbx-color-light-grey-2;
        border-bottom: 1px solid $smbx-color-white;
        &.active {
          background-color: white;
        }
        &:hover {
          color: $smbx-color-dark-grey-1;
          background-color: $smbx-color-white;
        }
      }

}
.tab-content {
  padding: 0px;
  //padding-top: 25px;
}
