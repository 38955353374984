// data cards with simple numbers
// (used in post-auction phases)

.card-plain-data {
  height: 50px;
  padding: 0px 25px 0px 25px;
  border-right: 1px solid $smbx-color-light-grey-2;
  border-bottom: 1px solid $smbx-color-light-grey-2;

    p {
      float: right;
      line-height: 50px;
    }
    .card-header {
      color: #666666;
      background: none;
      font-size: 12px;
      line-height: 50px;
      float:left;
      letter-spacing: $smbx-font-primary-letter-spacing;
    }
}
