// discarded unused code:

.button {
  min-width: 300px;
  width: 100%;
  display: block;
  color: $smbx-color-black;
  background-color: #F8E71C;
  text-transform: uppercase;
  word-spacing: 1px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding: 16px 0px 16px 0px;
  text-align: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    color: $smbx-color-black;
    text-decoration: none;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  }
}
