//
// DOCUMENTS SECTION
//

.documents-box {
  max-width: 910px;
}
.document-list {

  .documents-title {
    margin:  25px 0 35px 70px;
    font-size: 18px;
  }

  .file-row {
    margin: 0 110px;
    padding-bottom: 50px;
    padding-top: 15px;
  }

  .download-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    right: 0;
  }

      .document {
        padding-right: 25px;
        padding-left: 10px;
        margin-top: 13px;
        position: absolute;
      }
      .p {
        margin-top: -5px;
        position: relative;
        font-family: "Lato";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.5px;
        padding-left: 16px;
      }

      & li {
        height:75px;
        display: block;
        border-bottom: solid 1px $smbx-color-light-grey-2;
            & p {
              margin-left: 50px;
            }
            i {
            }
            .hover-controls {
              float: right;
              margin-top: -25px;
              margin-right: 25px;
            }
      }
      .row {
        border-bottom: 1px solid $smbx-color-light-grey-1;
      }
}
.smbx-content.documents {
  padding: 0px;
      & .p {
        line-height: 75px;
        margin-left: 15px;
      }
      & i {
        padding-top: 12px;
        padding-left: 25px;

      }
}
.document-list .row:hover .hover-controls,
.hover-edit:hover .hover-controls {
  opacity: 1;
}
