//
// ========== OFFERING DATA CARDS ==========
//

// VARIABLES

$smbx-db-component-data-cards-color-darken: 3%;


// CARDS with offering data
.card-data-box {
  background-color: white;
  //margin-top: 25px;
}
.card-data-offering {
  min-height: 100px;
  padding: 13px 25px 15px 25px;
  border-top: solid 1px $smbx-color-db-bg;
  border-right: solid 1px $smbx-color-db-bg;

    .card-header {
      color: #666666;
      background: none;
      font-size: 12px;
      letter-spacing: $smbx-font-primary-letter-spacing;
      //line-height: 16px;
    }
    .card-title {
      //color: $db-card-data-offering-color-data;
      margin: 0px;
      margin-top: 15px;
      padding: 0px;
      //font-size: 30px;
      //font-weight: 500;
      //line-height: 30px;
    }
    // cards with progress bar

    .progress {
      margin-top: 55px - 13px -1px;  // original-padding-border
      background-color: #CCCCCC;
      border-radius: 0px;
    }

    .progress-bar {


      @each $phase in $smbx-offering-phases {

        &.#{$phase} {

            background-color: map-get($smbx-colors, $phase);

            // darken color if first or last date of current status
            //&.darker {
            //    background-color: darken(
            //      map-get($smbx-colors, $phase),
            //      $smbx-db-component-data-cards-color-darken
            //    );
            //}
        }

      } // end generation of color classes
    }// .progress-bar

    // cards with text
    .card-text {
      margin-top: 53px - 13px - 1px; // original-padding-border
      color: $smbx-color-black;
    	font-size: 15px;
    	line-height: 20px;
    }

    //when bond results accepted - align right
    &.completed {

      & .card-title,
      & .card-header {
        text-align: right;
        float: right;
      }
    }
}// end .card-data-offering
