//
// SMBX COLORS
//

// all colors from the design styleguide (see invision files)
// with added $smbx-color- prefix, and dropped 0's

$smbx-color-yellow:             #F8E71C;

$smbx-color-dark-grey-1:        #4D4D4D;
$smbx-color-dark-grey-2:        #656565;

$smbx-color-mid-grey:           #9B9B9B;
$smbx-color-light-grey-1:       #DEDEDE;
$smbx-color-light-grey-2:       #EBEBEB;

$smbx-color-red-1:              #EE372A;
$smbx-color-red-2:              #ED5247;
$smbx-color-red-3:              #ED7E77;

$smbx-color-blue-1:             #00B0F4;
$smbx-color-blue-2:             #49C4F5;
$smbx-color-blue-3:             #7AD2F5;

$smbx-offering-phases:          ("roadshow", "prebid", "auction", "auction-closed");

$smbx-color-roadshow:           #00A1BA;
$smbx-color-prebid:             #00CCAA;
$smbx-color-auction:            #12CC31;
$smbx-color-auction-closed:     $smbx-color-mid-grey;

//additional colors

$smbx-color-black:              #000;
$smbx-color-white:              white;

// color list - keep updated

$smbx-colors: (

  yellow:             $smbx-color-yellow,

  dark-grey-1:        $smbx-color-dark-grey-1,
  dark-grey-2:        $smbx-color-dark-grey-2,

  mid-grey:           $smbx-color-mid-grey,

  light-grey-1:       $smbx-color-light-grey-1,
  light-grey-2:       $smbx-color-light-grey-2,

  red-1:              $smbx-color-red-1,
  red-2:              $smbx-color-red-2,
  red-3:              $smbx-color-red-3,

  blue-1:             $smbx-color-blue-1,
  blue-2:             $smbx-color-blue-2,
  blue-3:             $smbx-color-blue-3,

  roadshow:           $smbx-color-roadshow,
  prebid:             $smbx-color-prebid,
  auction:            $smbx-color-auction,
  auction-closed:     $smbx-color-auction-closed,

  black:              $smbx-color-black,
  white:              $smbx-color-white

);


// reassigned colors (dashboard=db)
$smbx-color-db-bg:              $smbx-color-light-grey-2;
$smbx-color-db-nav:             $smbx-color-dark-grey-2;
$smbx-color-db-nav-bg:          $smbx-color-black;
$smbx-color-db-nav-active:      $smbx-color-white;
$smbx-color-db-nav-hover:       $smbx-color-light-grey-2;
// end

// ============ BEGIN STYLING ================

// color helper classes: .color-name-{id}
// status colors: 'roadshow' / 'prebidd' / 'auction' / 'auction-closed'
// add .bg / .text classes as needed

// generate color helper classes
@each $color, $value in $smbx-colors {
  .#{$color} {
    &.text {
      color: #{$value};
    }
    &.bg {
      background-color: #{$value};
    }
  }
}// end @each






// 



// color classes
// smbx offering status color styling classes, use:
// status-name class: 'roadshow' / 'prebidd' / 'auction'
// along with 'text' / 'bg' classs as needed

/*
.roadshow {
    &.text {
      color: $smbx-color-roadshow;
    }
    &.bg {
      background-color: $smbx-color-roadshow;
    }
}
.prebid {
    &.text {
      color: $smbx-color-prebid;
    }
    &.bg {
      background-color: $smbx-color-prebid;
    }
}
.roadshow {
    &.text {
      color: $smbx-color-auction;
    }
    &.bg {
      background-color: $smbx-color-auction;
    }
}
*/
