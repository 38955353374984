//
// SMBX TYPOGRAPHY
//

// this files copies all the styles established in the
// design styleguide and assigns SCSS variables.
// $smbx-{element}-{property}, ex:

// Contents:
// - primary font variables  // we only have 1 font
// - import fonts
// - heading font variables // ~= primary, except weight/size
// - body font variables // ~= primary, except weight/size
// - styling

// h1-h6 & .h1-.h6
// p .p & .strong
// input, label & .label, .form-error

// =========== VARIABLES ==========

//primary font
$smbx-font-primary:                     "Lato";

$smbx-font-primary-weight-300:          300;// "Book"
$smbx-font-primary-weight-500:          500; // "Demi"
// for use:
$smbx-font-primary-weight-normal:       $smbx-font-primary-weight-300;
$smbx-font-primary-weight-bold:         $smbx-font-primary-weight-500;
$smbx-font-primary-letter-spacing:      0.5px;

// import fonts ** NOT RENDERING WELL
// @font-face {
//  font-family: "Maison Neue";
//  font-weight: 300; // Book
//  font-style: normal;
//  src: local('Maison Neue'),
//  url("../../assets/fonts/maison-neue/MaisonNeue-Book.woff2") format("woff2"),
//         url("../../assets/fonts/maison-neue/MaisonNeue-Book.woff") format("woff");
// }
// @font-face {
//  font-family: "Maison Neue";
//  font-weight: 500; // Demi
//  font-style: bold;
//  src: url("../../assets/fonts/maison-neue/MaisonNeue-Medium.woff2") format("woff2"),
//         url("../../assets/fonts/maison-neue/MaisonNeue-Medium.woff") format("woff");
// }

@font-face {
  font-family: "Lato";
  font-weight: normal;
  font-style: normal;
  src: url("../../assets/fonts/lato/Lato-Regular.ttf") format("ttf")
 }

//heading font
$smbx-font-heading:                     $smbx-font-primary;
$smbx-font-heading-weight:              $smbx-font-primary-weight-500; //default
$smbx-font-heading-weight-normal:       $smbx-font-primary-weight-300; //extra
$smbx-font-heading-weight-bold:         $smbx-font-primary-weight-500; //extra
$smbx-font-heading-letter-spacing:      $smbx-font-primary-letter-spacing;
$smbx-font-heading-color:               $smbx-color-black;


//body font
$smbx-font-body:                        $smbx-font-heading;
$smbx-font-body-weight:                 $smbx-font-primary-weight-300;
$smbx-font-body-weight-normal:          $smbx-font-primary-weight-300;
$smbx-font-body-weight-bold:            $smbx-font-primary-weight-500;

$smbx-font-body-letter-spacing:         $smbx-font-heading-letter-spacing;
$smbx-font-body-color:                  $smbx-color-dark-grey-1;
$smbx-font-body-size:                   15px;
$smbx-font-body-line-height:            25px;



// =========== ASSIGN VARIABLES TO ELEMENTS ==========

// heading tags. declared styles: font, color, weight, size, line-height

// H1 = MaisonNeue-Demi / 50 px / 60 px Leading / Black
$smbx-h1-font:                          $smbx-font-heading;
$smbx-h1-weight:                        $smbx-font-heading-weight;
$smbx-h1-size:                          50px;
$smbx-h1-line-height:                   60px;
$smbx-h1-color:                         $smbx-font-heading-color;

$h1: (
  font: $smbx-font-heading,
  weight: $smbx-font-heading-weight,
  size: 50px,
  line-height: 60px,
  color: $smbx-font-heading-color
);

// H2 = MaisonNeue-Demi / 30 px / 40 px Leading / Black
$smbx-h2-font:                          $smbx-font-heading;
$smbx-h2-weight:                        $smbx-font-heading-weight;
$smbx-h2-size:                          30px; // changes
$smbx-h2-line-height:                   40px; // changes
$smbx-h2-color:                         $smbx-font-heading-color;


$h1: (
  font: $smbx-font-heading,
  weight: $smbx-font-heading-weight,
  size: 50px,
  line-height: 60px,
  color: $smbx-font-heading-color
);


// H3 = MaisonNeue-Book / 30 px / 40 px Leading / Black
$smbx-h3-font:                          $smbx-font-heading;
$smbx-h3-weight:                        $smbx-font-heading-weight-normal; // changes from default
$smbx-h3-size:                          30px;
$smbx-h3-line-height:                   40px;
$smbx-h3-color:                         $smbx-font-heading-color;

// H4 = MaisonNeue-Demi / 20 px / 30 px Leading / Black
$smbx-h4-font:                          $smbx-font-heading;
$smbx-h4-weight:                        $smbx-font-heading-weight;
$smbx-h4-size:                          20px; // changes
$smbx-h4-line-height:                   30px; //changes
$smbx-h4-color:                         $smbx-font-heading-color;

// H5 = MaisonNeue-Demi / 20 px / 30 px Leading / Black
$smbx-h5-font:                          $smbx-font-heading;
$smbx-h5-weight:                        $smbx-font-heading-weight-normal;
$smbx-h5-size:                          20px;
$smbx-h5-line-height:                   30px;
$smbx-h5-color:                         $smbx-color-dark-grey-1;

// H6 = MaisonNeue-Book / 15 px / 25 px Leading / Dark Grey 01
$smbx-h6-font:                          $smbx-font-heading;
$smbx-h6-weight:                        $smbx-font-heading-weight;
$smbx-h6-size:                          15px; // changes
$smbx-h6-line-height:                   25px; //changes
$smbx-h6-color:                         $smbx-font-heading-color;

// p = MaisonNeue-Book / 20 px / 30 px Leading / Dark Grey 01
$smbx-p-font:                           $smbx-font-body;
$smbx-p-weight:                         $smbx-font-body-weight;
$smbx-p-weight-normal:                  $smbx-font-body-weight-normal;
$smbx-p-weight-bold:                    $smbx-font-body-weight-bold;
$smbx-p-size:                           $smbx-font-body-size;
$smbx-p-line-height:                    $smbx-font-body-line-height;
$smbx-p-color:                          $smbx-color-dark-grey-1;

// form label = MaisonNeue-Book / 15 px / 25 px Leading / Dark Grey 02
$smbx-label-font:                       $smbx-font-body;
$smbx-label-weight:                     $smbx-font-body-weight;
//$smbx-label-weight-normal:              $smbx-font-body-weight-normal;
//$smbx-label-weight-bold:                $smbx-font-body-weight-bold;
$smbx-label-size:                       $smbx-font-body-size;
$smbx-label-line-height:                $smbx-font-body-line-height;
$smbx-label-color:                      $smbx-color-dark-grey-2;

// form input = MaisonNeue-Book / 15 px / 25 px Leading / Black
$smbx-input-font:                       $smbx-font-body;
$smbx-input-weight:                     $smbx-font-body-weight;
//$smbx-input-weight-normal:              $smbx-font-body-weight-normal;
//$smbx-input-weight-bold:                $smbx-font-body-weight-bold;
$smbx-input-size:                       $smbx-font-body-size;
$smbx-input-line-height:                $smbx-font-body-line-height;
$smbx-input-color:                      $smbx-color-black;

// Form error = MaisonNeue-Book / 15 px / 25 px Leading / Red 01
$smbx-form-error-font:                  $smbx-font-body;
$smbx-form-error-weight:                $smbx-font-body-weight;
$smbx-form-error-size:                  $smbx-font-body-size;
$smbx-form-error-line-height:           $smbx-font-body-line-height;
$smbx-form-error-color:                 $smbx-color-red-1; //changes


// =========== END VARIABLES ==========



// =========== BEGIN STYLING ==========

// headings

h1, .h1 {
  font-family:    $smbx-h1-font;
  font-weight:    $smbx-h1-weight;
  font-size:      $smbx-h1-size;
  line-height:    $smbx-h1-line-height;
  color:          $smbx-h1-color;
}

h2, .h2 {
  font-family:    $smbx-h2-font;
  font-weight:    $smbx-h2-weight;
  font-size:      $smbx-h2-size;
  line-height:    $smbx-h2-line-height;
  color:          $smbx-h2-color;
}

h3, .h3 {
  font-family:    $smbx-h3-font;
  font-weight:    $smbx-h3-weight;
  font-size:      $smbx-h3-size;
  line-height:    $smbx-h3-line-height;
  color:          $smbx-h3-color;
}

h4, .h4 {
  font-family:    $smbx-h4-font;
  font-weight:    $smbx-h4-weight;
  font-size:      $smbx-h4-size;
  line-height:    $smbx-h4-line-height;
  color:          $smbx-h4-color;
}

h5, .h5 {
  font-family:    $smbx-h5-font;
  font-weight:    $smbx-h5-weight;
  font-size:      $smbx-h5-size;
  line-height:    $smbx-h5-line-height;
  color:          $smbx-h5-color;
}

h6, .h6 {
  font-family:    $smbx-h6-font;
  font-weight:    $smbx-h6-weight;
  font-size:      $smbx-h6-size;
  line-height:    $smbx-h6-line-height;
  color:          $smbx-h6-color;
}

// paragraph styling

p, .p {
  font-family:    $smbx-p-font;
  font-weight:    $smbx-p-weight;
  font-size:      $smbx-p-size;
  line-height:    $smbx-p-line-height;
  color:          $smbx-p-color;

      &.strong {
        font-weight: $smbx-p-weight-bold;
      }
}

// label tag styling

label, .lable {
  font-family:    $smbx-label-font;
  font-weight:    $smbx-label-weight;
  font-size:      $smbx-label-size;
  line-height:    $smbx-label-line-height;
  color:          $smbx-label-color;
}

input[type="text"] {
  font-family:    $smbx-input-font;
  font-weight:    $smbx-input-weight;
  font-size:      $smbx-input-size;
  line-height:    $smbx-input-line-height;
  color:          $smbx-input-color;
}

.form-error {
  font-family:    $smbx-form-error-font;
  font-weight:    $smbx-form-error-weight;
  font-size:      $smbx-form-error-size;
  line-height:    $smbx-form-error-line-height;
  color:          $smbx-form-error-color;
}

//global line spacing
a,
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
p, .p,
label,
input[type="text"],
input[type="number"],
.form-error {
  letter-spacing: $smbx-font-primary-letter-spacing;//;$smbx-font-heading-letter-spacing;
}
a {
  color: $smbx-color-mid-grey;
  &:hover {
    color: $smbx-color-dark-grey-1;
    text-decoration: none;
  }
}

// =========== END STYLING ==========
