.signin {

    .container-fluid {
      background: url("../../../../assets/img/login-bg.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
      display: block;

    }
    .logo {
      height: 100px;
      padding: 25px;
    }
    form.signin-form {
      width: 500px;
      height: 500px;
      margin: auto;
      padding: 50px;
      background-color: white;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    }
    h2 {
      font-size: 20px;
      padding-top: 5px;
      text-align: center;
      margin-bottom: 25px;
    }
    form input {
      //font-size: 15px;
      //line-height: 50px - 15px;
      //border-color: #DEDEDE;
      margin-top: 0px;
    }
    form input:focus {
      border-color: #DEDEDE;
    }
    input#username {
      margin-bottom: 15px;
    }
    input#password {
      margin-bottom: 0px;
    }
    label {
      color: #666666;
      font-size: 15px;
      line-height: 20px;
      float: none;
      margin: 5px 0px 5px 0px;
    }
/*    .btn {
      border: none;
      background-color: #F8E71C;
      color: $smbx-color-black;
      text-transform: uppercase;
      font-size: 15px;
      letter-spacing: 1px;
      padding: 16px 0px 16px 0px;
      font-weight: 500;
      width: 300px;
      margin: auto;
      margin-top: 60px;line-height: 50px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

      &:hover {
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
      }
      */
      .btn {
        margin-top: 75px;
      margin-left: 50px;
      }

    .forgot {
      color: #9B9B9B;
      line-height: 40px;
    }
} // end .signin
