/*!
 * Bootstrap v4.0.0-beta.3 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "utilities";
@import "print";

//custom for dashboard

@import "smbx";

.Notification__item___1d3Zz, .Notification__item__message___3NDDt, .Notification__item__btnBar___2ulqW{
    font-family: 'Lato' !important
}


form .custom-file-upload {
    border: 1px solid $primary;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    padding-right: 25px;
    padding-left: 25px;
    cursor: pointer;

}

.custom-file-upload input {
    display: none;
}

.custom-file-upload .material-icons {
    line-height: inherit;
}

.action-delete:hover i{
    color: $red !important;
    
}
.action-delete:hover label{
    color: $red !important;
    cursor: pointer;
    
}

.action-info:hover i {
    color: $blue !important;
}
.action-info:hover label {
    color: $blue !important;
    cursor: pointer;
}
