//
// ========== DASHBOARD STYLESHEET ===========
//
// this stylesheet contains all the issuer dashboard styles
//
// all the components are in /components/ directory
// this file is primarily to import only, but:
// style rules in progress can be added after the imports
//
// for now, the /components/ directory also contains
// section related styles.

// GENERAL IMPORT
@import "dashboard/menu";
@import "dashboard/header";
@import "dashboard/forms";

// IMPORT COMPONENTS
@import "dashboard/components/calendar";
@import "dashboard/components/post";
@import "dashboard/components/team-member";
@import "dashboard/components/investor-list";
// primary offering data (hero:roadshow,prebid/auction):
@import "dashboard/components/data-cards";
// top bar with offering status, deadlines, time left etc.
@import "dashboard/components/offering-status-card";
@import "dashboard/components/accept-outcome";
@import "dashboard/components/table";
@import "dashboard/components/signin";
// uploaded documents section:
@import "dashboard/components/documents";
// unused/discarded styles (just for backup)
@import "dashboard/components/bin";
// showing offering data after auction phase
@import "dashboard/components/card-plain-data";
@import "dashboard/components/company-cover";



// ========== /END IMPORTS ===========



// GENERAL STYLES:

// styles below are work in progress and should be moved
// into a unique file as soon as they become organizable

body {
  color: $smbx-color-dark-grey-1;
}
// empty div to add 25px spacing
.spacer {
  display: block;
  height: 25px;
}
.icon-button {

      & i {
        color: $smbx-color-black;
      }
}

.section-title {
  margin:  0 0 35px 45px;
  font-size: 18px;
}

.hover-controls {
  float: right;
  opacity: 0;
  -webkit-transition-property: opacity; /* Safari */
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-property: opacity;
  transition-duration: 0.3s;

  //-webkit-transition-delay: 150ms;
  //-moz-transition-delay: 150ms;
  //-o-transition-delay: 150ms;
  //transition-delay: 150ms;

      & a {
        line-height: 79px;
        height: 75px;
        display: block;
        width: 75px;
        float: left;
        padding-top: 5px;
        padding-left: 3px;
            & i {
              width: 50px;
              padding-left: 25px;
            }
      }
      & a.icon-button:hover {
        background-color: $smbx-color-yellow;
      }
}
.dash {
  padding: 0px;
  background-color: $smbx-color-db-bg;
}

.dash-content-box {
  padding: 25px;
  max-width: 906px;
}
.content {
  background-color: white; // not in use
}
.stats {
  margin-top: 25px; // not in use
}

// material icon styles
// copied from material design website as reference
/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }
// end material icon styles
