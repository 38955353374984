//
// OFFERING STATUS BAR
//

.offering-status-card {
  height: 50px;
  background-color: transparent;
      p {
        line-height: 50px;
        padding: 0px 25px 0px 25px;
      }
      i {
        display: inline;
        vertical-align: middle;
        font-size: 20px;
        width: 20px;
        height: 20px;
        position: relative;
        margin-right: 5px;
      }
      .card-title {
        color: white;

        // generate colors for title bg

            @each $phase in $smbx-offering-phases {
              &.#{$phase} {
                  background-color: map-get($smbx-colors, $phase);
              }
            } // end generation of color classes

      }// end .card-title

} // end .card-offering-status
