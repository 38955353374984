
.company-cover {
  margin-top: 25px;
  .hover-controls {
    position: relative;
    float: right;
    top: 75px;//-323px;
    margin-right: 0px;
    opacity: 1 !important;
    display: block !important;
    //z-index: 1000;
  }
  &:hover a.icon-button {
    display: block;
  }
  .cover-image {
    margin-top: -75px;
    height: auto;//323px;
    //height: auto;
  }
  a.icon-button {
    //display: none;
  }
}
.cover-image { // probably not in use
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}
.company-cover { // probably not in use
      & img {
        width: 100%;
      }
}
.company.buttons.box {  // probably not in use
  height: 100px;
  display: block;
}
