//
//
//

.smbx-content.investor-list {
  padding: 0px;
}
.investor-list {
  .row.investor {
  border-bottom: 1px solid $smbx-color-light-grey-2;
 }
  i, .name, .amount {
    padding: 25px;
  }
  i {
    color: $smbx-color-light-grey-1;
  }
  a.icon-button {
    padding-top: 0px;
  }
  .name {
    padding-left: 0px;
  }
  .amount {
    padding-left: 0px;
  }
  p {
    margin: 0px;
  }
}
