//
// FORM ELEMENTS
//
$smbx-form-spacing: 25px;

form label {
  
  font-family: "Lato";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.5px;
  color: #08080f;

  &.label-right {
    margin-top: $smbx-form-spacing/1.3;
    margin-bottom: $smbx-form-spacing/2;
    float: right;
    padding-right: 25px;
    width: 220px;
    text-align: end;
  }
}

.item-row {
  margin: 0 85px;

  input, textarea{
    width: 100% !important;
  }
}

form input, form input[type="text"], input[type="number"], form select, .input {
  //line-height: 50px;
  font-size: 15px;
  font-weight: $smbx-font-body-weight;
  height: 50px;
  width: 300px;
  background-color: transparent;
  border: 1px solid $smbx-color-light-grey-1;
  padding: 10px;
  margin-top: $smbx-form-spacing/2;
  margin-bottom: $smbx-form-spacing/2;
  color: $smbx-color-black;
  border-radius: 0px;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: $smbx-font-primary-letter-spacing;
  &.full {
    width: 100%;
  }
  &:focus, &.input:focus {
        border: 1px solid $smbx-color-mid-grey;
        color: $smbx-color-black;
        outline: none;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
        -moz-appearance: none;
        appearance: none;
      }
}
form input[type="number"].currency {
  padding-left: 50px;
  background: url('../../../assets/img/form-input-dollar-sign.png') no-repeat left;
}
form .textarea {
  margin-top: $smbx-form-spacing/3;
  width: 100%;
  border: solid 1px #cdcdcd;
  background-color: #f2f2f2;
  padding: 25px 15px;
  color: $smbx-color-dark-grey-2;
  border-radius: 0px;
  outline: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  font-family: "Lato";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.5px;

  *{
    font-family: "Lato";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.5px;
    color: $smbx-color-dark-grey-2;
  }

  &:focus {
    border: 1px solid $smbx-color-mid-grey;
  }

  &_fund {
    @extend .textarea;
    padding: 10px 15px;
    overflow: auto;
  }

  &_small {
    max-width: 300px;
  }
}
form .select {
  background: $smbx-color-light-grey-2;
  display: block;
  height: 50px;
  width: 300px;
  margin-top: $smbx-form-spacing/2;
  margin-bottom: $smbx-form-spacing/2;
  overflow: hidden;
  //border: 1px solid $smbx-color-light-grey-1;
  background: $smbx-color-light-grey-2 url(../../../assets/img/form-select.png) no-repeat right top;
  :hover {
    border: 0px solid $smbx-color-light-grey-1;
    background: url(../../../assets/img/form-select.png) no-repeat right 0px  bottom -1px;
    //background-color: $smbx-color-yellow;
   //border: 1px solid $smbx-color-light-grey-1;
   //background-color: red;
  }
  :active {
    background-color: $smbx-color-yellow;
  }
  &.locked {
    //padding-left: 50px;
    :hover {
      background: none;
      cursor: not-allowed;
    }
  }
}
form select  {
  border: 0px;//1px solid $smbx-color-light-grey-1;
  background: transparent;//$smbx-color-light-grey-2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;       /* remove default arrow */
  color: $smbx-color-black;
  font-size: 15px;
  line-height: 15px;
  padding: 2px 10px;
  padding-right: 25px;
  width: 300px;
  font-weight: $smbx-input-weight;
  margin: 0px;

  &:focus {
    outline: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    -moz-appearance: none;
    appearance: none;
    }
}

input[type="button"], .btn {
  max-width: 300px;
  width: 100%;
  background-color: $smbx-color-yellow;
  height: 50px;
  //line-height: 50px;
  font-size: 15px;
  border: 0px solid;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: $smbx-color-black;
  cursor: pointer;
  margin: 0px 10px 0px 0px; //0px 5px 0px 5px;

  -webkit-transition-property: margin-top box-shadow; /* Safari */
  -webkit-transition-duration: 0.3s; /* Safari */
  transition-property: margin-top box-shadow;
  transition-duration: 0.3s;
  //box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: $smbx-font-primary-letter-spacing;
  margin-top: 1px;


  &:hover {
    background-color: $smbx-color-yellow;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    margin-top: 0px;
    margin-bottom: 1px;
  }
  &.disabled {
    background-color: $smbx-color-light-grey-2 !important;
    color: $smbx-color-mid-grey;
  }
  &.disabled:hover {
    box-shadow: none;
    cursor: not-allowed;
    margin-bottom: auto;
    //margin-top: 1px;
  }
  &.full {
    width: 100%;
  }
  .primary {
    background-color: $smbx-color-yellow;
  }
  &.secondary {
    background-color: $smbx-color-white;
    border: 1px solid $smbx-color-yellow;
    color: $smbx-color-mid-grey;
    &:hover {
      box-shadow: none;
      border: 1px solid $smbx-color-dark-grey-1;
      color: $smbx-color-dark-grey-1;
    }
  }
  &.small {
    width: 200px;
  }
  &.text { // simple text buttons
    border: none;
    text-transform: none;
    background-color: transparent;
    color: $smbx-color-dark-grey-2;
    font-weight: $smbx-font-body-weight;
    width: auto;
    &:hover {
      border: 0px;
      box-shadow: none;
      color: $smbx-color-black;
    }
  }
}
a.btn {
    line-height: 50px - 12px;
    &:hover {
      color: $smbx-color-black;
    }
}
form .form-error {
  border: 1px solid $smbx-color-red-3 !important;
  &:focus {
    border: 1px solid $smbx-color-red-3;
  }
}
.form-error-text {
  color: $smbx-color-red-3;
  &:focus {
    border: 1px solid $smbx-color-red-3;
  }
}
.upload {
  margin-top: $smbx-form-spacing/2;
  display: block;
  //overflow: hidden;
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
}
.file-upload {
  margin: 0;
  padding: 0;
  font-size: 20px;
  width: 150px;
  height: 150px;
  opacity: 0;
  &:hover {
    cursor: pointer;
  }
}

.ratio {
  &_16-9{
    padding-top: 56.25%;
  }

  &_4-3{
    padding-top: 75%;
  }

  &_1-1{
    padding-top: 100%;
  }

  img {
     width: 100%;
     height: 100%;
     position: absolute;
     top: 0;
     right: 0;
     left: 0;
     bottom: 0;
  }
}
