//
// main dashboard left navigation menu
//

// variables

$smbx-db-nav-width: 225px;
$smbx-db-nav-padding: 15px;
$smbx-db-nav-font-size: 15px;

// begin styles:

.nav-col {
  max-width: $smbx-db-nav-width;
}
.nav-left {
  width: $smbx-db-nav-width;
  background-color: $smbx-color-db-nav-bg;
  color: $smbx-color-db-nav;
  padding: $smbx-db-nav-padding;
  height: 100vh;
  position: fixed;

    a.logo {
      margin-bottom: 13px;
      padding: 0px 0px 0px 0px;
      padding: 10px
    }
    .logo-small {
      display: none;
    }
    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;
      padding-top: 13px;
    }
    li {
      margin: 0px;
      padding: 0px;
      height: 48px;
    }
    a {
      color: $smbx-color-db-nav;
      font-size: $smbx-db-nav-font-size;
      display: block;
      padding: 10px;
      -webkit-transition-property: color; /* Safari */
      -webkit-transition-duration: 0.3s; /* Safari */
      transition-property: color;
      transition-duration: 0.3s;


    }
    a span {
      vertical-align: top;
      line-height: 27px;
      margin-left: 5px;

    }
    a i {
      margin: 0px;
      padding: 0px;
      font-size: 24px;
      width: 37px;
      vertical-align: middle;

    }
    a.active {
      color: $smbx-color-db-nav-active;
      text-decoration: none;
    }
    a:hover {
      color: $smbx-color-db-nav-hover;
      text-decoration: none;
    }
    .fixed-bottom {
      position: absolute;
      padding-left: $smbx-db-nav-padding;
      bottom: $smbx-db-nav-padding;
    }
} // end .nav-left

// collapse styling

.nav-closed {
  max-width: 75px;

      .logo {
        display: none;
      }
      .logo-small {
        display: block;
        margin-top: 25px;
        margin-bottom: 13px;
      }
      span {
        display: none;
      }
      li {
        height: 48px;
      }
      ul {
        padding-top: 0px;
      }

}

// end main left navigation
