//
// ACCEPT OUTCOME card
//

.outcome {
  //margin: 25px 0px 25px 0px;
  font-size: 15px;
  //
      p {
        text-align: right;
        margin-top: 15px;
        display: block;
        padding-right: 15px;
      }
      p a {
        //color: #9B9B9B !important;
      }
      .button {
        max-width: 100%;
      }
}
