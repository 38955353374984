//
// DASHBOARD HEADER STYLES
//
strong { font-weight: bold !important; } 
// top dashboard bar
.top-bar {
  padding: 50px 25px 17px 25px;
  background-color: white;
  //min-width: 950px;
  //height: 100px;
}
.col-excode {
  max-width:125px;
  //width: 130px;
}

.excode {
    i {
      float: left;
      text-transform: uppercase;
      font-style: normal;
      text-align: center;
      background-color: $smbx-color-black;
      color: white;
      font-size: 18;
      display: block;
      width: 23px;
      height: 24px;
      margin: 1px;
      line-height: 25px;
    }
}// end .excode

.company-bond-name {
  padding: 0px;

    h1 {
      font-size: 20px;
      line-height: 25px;
      margin-left: 0px;
      padding: 0px;
    }
}
.username {
  right: 20px;
  position: relative;
  //width: 150px;

    a {
      padding: 10px;
      color: black;
      height: 20px;
      line-height: 22px;
      font-size: 15px;
    }
    a i {
      margin-left: 5px;
      position: absolute;
    }
    & a:hover {
      text-decoration: none;
      color: $smbx-color-dark-grey-1;
    }

}// end .username

.username-dropdown {
  display: none;
  width: 175px;
  //margin-right: 30px;
  background-color: $smbx-color-white;
  position: absolute;
  right: -20px;
  //padding-top: 25px;
  z-index: 100;
  //box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

      & a {
        //float: left;
        padding: 0px 20px 0px 20px;
        color: $smbx-color-mid-grey;
        line-height: 50px;
        font-size: 15px;
        display: block;
        height: 50px;
      }
      & span {
        //float: left;
        padding: 0px 20px 0px 20px;
        color: $smbx-color-mid-grey;
        line-height: 50px;
        font-size: 15px;
        display: block;
        height: 50px;
        cursor: pointer;
      }
      .signout:hover{
        color: black;
      }
      .shadow {
        margin-top: 26px;
        height: auto;
        display: block;
        border-top: 1px solid $smbx-color-light-grey-2;
        box-shadow: 0 2px 2px 1px $smbx-color-light-grey-1;
      }
}
.username:hover .username-dropdown {
  display: block;
}
