//
// news posts
//
.smbx-content.post {
  padding: 0px;
}
.post article {
  padding: 25px;
  i {
    float: left;
    margin-right: 10px;
    color: $smbx-color-light-grey-1;
  }
  .meta {
    color: $smbx-color-dark-grey-1;
    margin-bottom: 25px;
  }
  .title {
    margin-bottom: 15px;
  }
  border-bottom: 1px solid $smbx-color-light-grey-2;
}
