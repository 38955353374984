//
// CALENDAR COMPONENT
//


// CLASSES:

// .day : for day heading boxes
//.none : empty / transparent day-box
// .first / .last : to create margin between differnt offering stages,
// but also add .edge if the html generating loop if such box occurs at beginning / end of any calendar row.


// VARIABLES

// heading text color of columns
$smbx-db-component-calendar-color-days:     $smbx-color-dark-grey-2;
// how much to darken the color of first / last day boxes
$smbx-db-component-calendar-color-darken:   3%;


.calendar-box {
}
.calendar {
  list-style: none;
  display: block;
  margin: 0px;
  padding: 0px;
  width: 46*7px;
  font-size: 15px;
  float: right;

  li {
    width: 46px;
    margin-bottom: 4px;
    height: 46px;
    padding-top: 13px;
    float: left;
    color: white;
    text-align: center;
    font-weight: 500;
    line-height: 20px;
    display: block;

    // days of the week
    &.day {
      color: $smbx-db-component-calendar-color-days;
    }
    // transparent day-box
    &.none {
      background-color: transparent;
    }
    &.first { // if it's first day of a new offering phase
      width: 44px; // reduce width and
      margin-left: 2px; // add equal margin towards previous day

        // but reset if it's first / last box of the row

        &.edge, // if adding offeringn status title in rows, use .edge

        &:nth-child(1),
        &:nth-child(8),
        &:nth-child(15),
        &:nth-child(22),
        &:nth-child(29)
        &:nth-child(36)
        {
          margin-left: 0px;
          width: 46px;
        }
    }
    &.last { // if it's the last day of a new offering phase
      width: 44px; // reduce width and
      margin-right: 2px; // add equal margin towards the next day-box

        //but reset if it's first / last box of the row

        &.edge, // if adding offeringn status title in rows, use .edge

        &:nth-child(7),
        &:nth-child(14),
        &:nth-child(21),
        &:nth-child(28),
        &:nth-child(35),
        &:nth-child(42)
        {
          margin-right: 0px;
          width: 46px;
        }
    }

    // generate color classes for offering phases

    @each $phase in $smbx-offering-phases {

      &.#{$phase} {

          background-color: map-get($smbx-colors, $phase);

          // darken color if first or last date of current status
          &.last, &.first {
              background-color: darken(
                map-get($smbx-colors, $phase),
                $smbx-db-component-calendar-color-darken
              );
          }
      }

    } // end generation of color classes

  }// end  li
}// end .calandar


// END CALENDAR COMPONENT
