//
// TEAM MEMBER
//
.smbx-content.team-list {
  padding: 0px;
}
.team-member {
  padding: 25px;
  border-bottom: 1px solid $smbx-color-light-grey-2;
  height: 150px;

      .profile-photo {
        float: left;
        margin-right: 25px;
      }
      .name {
        padding-top: 25px;
      }
      .position {

      }
}
