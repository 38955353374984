table {
  table-layout: fixed;
}
th {
  width: calc(100% / 7);
}
td {
  width: calc(100% / 7);
}

.capitalize {
  text-transform: capitalize;
}

/* Buttons */
.btn {
  &__primary {
    border: 0;
    width: 100%;
    height: 40px;
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
    color: #3b3f46;
    background-color: #fad92d;
    text-transform: capitalize;
    border-radius: 4px;
    transition: background-color 0.3s;
    &:hover {
      background-color: #ffef64;
    }
    &_disabled {
      background-color: #e5e7ed !important;
      color: #82868e !important;
    }
  }
  &__secondary {
    border: 2px solid #fad92d;
    width: 100%;
    height: 40px;
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
    color: #3b3f46;
    background-color: transparent;
    text-transform: capitalize;
    border-radius: 4px;
    transition: border 0.3s;
    &:hover {
      border: 2px solid #ffef64;
    }
    &_disabled {
      background-color: #e5e7ed !important;
      color: #82868e !important;
    }
  }
}

/* Idle Modal */
.idle {
  &_modal {
    &_title {
      font-weight: bold;
    }
    &_borderTop {
      width: 100%;
      height: 10px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fad92d;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}

/* SMBX Buttons */
.button_smbx {
  width: 100%;
  height: 40px !important;
  font-family: 'Lato' !important;
  font-size: 18px !important;
  font-weight: bold !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  color: #3b3f46 !important;
  background-color: #fad92d !important;
  text-transform: capitalize !important;
  border-radius: 4px;
  &:hover {
    background-color: #ffef64 !important;
  }
  &_sec {
    width: 100%;
    height: 40px !important;
    font-family: 'Lato' !important;
    font-size: 18px !important;
    font-weight: bold !important;
    line-height: 1 !important;
    letter-spacing: 0.5px !important;
    color: #3b3f46 !important;
    text-transform: capitalize !important;
    border-radius: 4px;
    border: solid 2px #fad92d !important;
    @media screen and (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }
}

/* Typography */
h3 {
  width: 100%;
  font-family: 'Lato' !important;
  font-size: 30px !important;
  font-weight: bold !important;
  line-height: 1.5 !important;
  letter-spacing: 1px !important;
  color: #3b3f46 !important;
  text-align: center;
}

h4 {
  width: 100%;
  font-family: 'Lato' !important;
  font-size: 16px !important;
  font-weight: normal !important;
  line-height: 1.5 !important;
  letter-spacing: 0.5px !important;
  text-align: center;
  color: #82868e !important;
}

/* Header */
.header {
  &_content {
    padding: 13px;
    background: linear-gradient(90deg, #000, #3b3f46);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.45);
  }
  &_logo {
    &_centered {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 39px;
      }
    }
  }
}

/* Main */
.main {
  background-color: #f6f8fe;
  height: 100vh;
  &_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &_centered {
    position: relative;
    width: 420px;
    @media screen and (max-width: 470px) {
      width: 90vw;
    }
  }
  &_bussiness {
    width: 100%;
    line-height: 54px;
    text-align: center;
    font-size: 36px;
    color: #3b3f46;
    font-weight: 700;
    @media screen and (max-width: 670px) {
      font-size: 28px;
    }
  }
  &_form {
    color: #3b3f46;
    box-shadow: 0px 0px 8px 0px rgba(194, 199, 207, 1);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 420px;
    @media screen and (max-width: 470px) {
      width: 90vw;
    }
    &_borderTop {
      width: 100%;
      height: 10px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fad92d;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &_title {
      font-weight: 900;
      font-size: 24px;
      line-height: 36px;
    }
    & label {
      font-size: 14px;
      line-height: 24px;
      color: #82868e;
      font-weight: 400;
      margin-bottom: 0;
    }
    & input {
      border-radius: 4px !important;
      width: 100% !important;
      color: #3b3f46 !important;
      margin: 0 !important;
    }
    &_passwordIcon {
      position: absolute;
      right: 30px;
      cursor: pointer;
      color: #c2c7cf;
    }
  }
  &_divider {
    background-color: #82868e !important;
    height: 1px;
  }
  &_link_primary {
    color: #4bbeff;
    font-size: 14px;
    text-decoration: underline;
  }
  &_link_secondary {
    color: #82868e;
    font-size: 14px;
    font-weight: 600;
  }
}